import {
  AdditionalPaymentPlanDataType,
  DoctorType,
  PaymentPlanType,
} from './types';
import { priceCurrencyToString } from './payment';
import {
  BillStatus,
  CUSTOM_DATE_FNS_FORMAT,
  CUSTOM_LONG_DATE_FORMAT,
  messageFromError,
  messageFromErrorChangePayment,
  newActivateDeactivate,
} from './ui';
import { format } from 'date-fns';
import moment from 'moment';

export const PATIENT_MSG = 'Patient';
export const DOCTOR_MSG = 'Doctor';
export const BUY_SUBSCRIPTION_NOW_MSG = 'Buy Now';
export const UNAVAILABLE_ROLE_MSG = 'Unavailable role';
export const NEW_MESSAGE_NOTIFICATION_TOGGLE = 'New Message Notifications On';
export const TOGGLE_NOTIFICATION_HELP =
  'Enabling/disabling email notification when a new message is received';
export const SUCCESS_SEND_INVITE = 'Invite was send successfully';
export const FAILURE_SEND_DOCTOR_INVITE = 'Invalid data for create new doctor';
export const E_MAIL_MSG = 'E-mail';
export const PASSWORD_MSG = 'Password';
export const CONFIRM_PASSWORD_MSG = 'Confirm password';
export const FULLNAME_MSG = 'Full name';
export const LAST_NAME_MSG = 'Last name';
export const FIRST_NAME_MSG = 'First name';
export const INVITED_BY = 'Invited by';
export const PROMO_CODE_MSG = 'Invitation code';
export const USE_BEFORE_MSG = 'Use before';
export const QUANTITIES_MSG = 'Quantities';
export const USED_AND_CAPACITY_MSG = 'Used / Capacity';
export const DESCRIPTION_MSG = 'Description';
export const POSITION_MSG = 'Name Of Practice';
export const NUMBER_OF_PATIENTS_MSG = 'Patients';
export const MAX_DOCTORS_MSG = 'Max number of doctors';
export const MAX_PATIENTS_MSG = 'Max number of patients';
export const MAX_PATIENTS_PLACEHOLDER = 'Please choose max number of patients';
export const LARGE_LIMIT_PATIENTS_PLACEHOLDER = 'I want more than 200 patients';
export const NO_LIMIT_MSG = 'No Limit';
export const INVITES_BEFORE = 'Invites before';
export const NEWS_NOTIFICATION_SUBSCRIPTION_MSG =
  'Sign up for MenHealth email newsletter to receive news and updates about our platform and mobile app';
export const NEWS_NOTIFICATION_PROFILE_SUBSCRIPTION_MSG =
  'MenHealth email newsletter subscription';
export const PATIENT_UNLIMITED_ACCESS_MSG = 'Patients have unlimited access';
export const TRIAL_SUBSCRIPTION_MSG = 'Trial subscription';
export const PATIENT_NO_UNLIMITED_ACCESS_MSG =
  'Patients have not unlimited access';
export const DOCTOR_UNLIMITED_ACCESS_MSG = 'Doctors have unlimited access';
export const DOCTOR_NO_UNLIMITED_ACCESS_MSG =
  'Doctors have not unlimited access';
export const STATUS_MSG = 'Status';
export const GIVES_SUBSCRIPTION_MSG = 'Gives subscription';
export const END_DATE_MSG = 'End date';
export const RESEND_INVITE = 'Resend invite';
export const INVITES_BEFORE_MSG = 'Invites before';
export const MANUAL_SUBSCRIBED_TILL_DATE = 'Subscribed till date';
export const NOT_ALL_SSI_RESULT_PREPARED_MSG = 'Not all BPH results';
export const CAN_NOT_SAVE_SSI_RESULTS_NSG = 'Can not save SSI results';
export const SSI_RESULTS_SAVED_MSG = 'SSI results saved successfully';
export const RESULTS_MSG = 'Results';
export const SIGN_IN_MSG = 'Sign In';
export const ALREADY_HAVE_AN_ACCOUNT = 'Already have an account? Please ';
export const SIGN_UP_MSG = 'Sign Up';
export const SIGN_UP_PATIENT_MSG = 'Sign Up As Patient';
export const SIGN_UP_DOCTOR_MSG = 'Sign Up As Doctor';
export const INVALID_AUTH_PAYLOAD = 'No have user data';
export const MESSAGES_MSG = 'Messages';
export const PROFILE_MSG = 'Profile';
export const LOGOUT_MSG = 'Log out';
export const EDIT_PROFILE_MSG = 'Edit profile';
export const EDIT_CLINIC_MSG = 'Edit clinic';
export const EDIT_DOCTOR_MSG = 'Edit doctor';
export const BIRTHDAY_MSG = 'Birthday';
export const BIRTH_DAY_MSG = 'Birth day';
export const MEDICAL_ID_MSG = 'Medical ID';
export const ADDITIONAL_INFO_FIELD_MSG = 'Additional info';
export const PHONE_MSG = 'Phone';
export const ADDITIONAL_INFO_MSG = 'Info';
export const FORGOT_YOUR_PASSWORD_MSG = 'Forgot your password?';
export const REGISTRATION_NEW_ACCOUNT_MSG = 'Create New Account';
export const CANCEL_MSG = 'Cancel';
export const PURCHASE_MSG = 'Purchase';
export const CHANGE_MSG = 'Change';
export const INVALID_BRAINTREE_INSTANCE_MSG = 'Payment is not loading yet';
export const SUCESSFULLY_CHANGE_PAYMENT_METHOD_MSG =
  'Successfully change payment method';
export const SUCESSFULLY_PAYMENT_MSG = 'Payment Processed Successfully';
export const FAILED_CANCEL_SUBSCRIPTION_MSG = 'Failed cancel subscription';
export const FAILED_CREATE_SUBSCRIPTION_MSG = 'Failed create subscription';
export const FAILED_CHANGE_FREE_TRIAL_CLINIC_SUBSCRIPTION =
  'Failed change free trial subscription';
export const FAILED_CHANGE_SUBSCRIPTION_PLAN_MSG =
  'Failed change subscription plan';
export const SUBSCRIPTION_MSG = 'Subscription';
export const CANCEL_SUBSCRIPTION_MSG = 'Cancel subscription';
export const STOP_RENEW_SUBSCRIPTION_MSG = 'Stop renew subscription';
export const OFFER_SUBSCRIPTION = 'Offer subscription';
export const MAX_AVATAR_SIZE_MSG = 'Max size is 2 MB';
export const CHANGE_PAYMENT_METHOD_MSG = 'Change Payment Method';
export const CONFIRM_CHANGE_PLAN = 'Confirm change';
export const CHANGE_ACTIVE_TIER = 'Change active tier';
export const RENEW_SUBSCRIPTION_MSG = 'Renew subscription';
export const SWITCH_ANOTHER_SUBSCRIPTION_MSG = 'Try another subscription';
export const BACK_TO_SUBSCRIPTION_MSG = 'Back to current subscription';
export const START_PENDING_SUBSCRIPTION_MSG = 'Start subscription';
export const SUCCESSFULLY_CANCEL_SUBSCRIPTION_MSG =
  'Subscription successfully canceled';
export const SUCCESSFULLY_CHANGE_SUBSCRIPTION_PLAN_MSG =
  'You have successful changed your subscription plan';
export const SUCCESSFULLY_CREATED_CLINIC_SUBSCRIPTION_PLAN_MSG =
  'You have successful created the clinic subscription plan offer';
export const SUCCESSFULLY_SET_CLINIC_FREE_TRIAL_SUBSCRIPTION_PLAN_MSG =
  'You have successfully set free trial clinic subscription';
export const SUCCESSFULLY_STOP_CLINIC_FREE_TRIAL_SUBSCRIPTION_PLAN_MSG =
  'You have successfully stopped free trial clinic subscription';
export const PAYMENT_PREVIEW = 'Payment for: ';
export const SAVE_MSG = 'Save';
export const CHANGE_PHOTO_MSG = 'Change photo';
export const HISTORY_MSG = 'Test History';
export const DIARY_MSG = 'Test Diary';
export const BLADDER_DIARY_MSG = 'Bladder Diary';
export const CLINIC_MSG = 'Clinic';
export const PROVIDER_MSG = 'Provider';
export const CLINICS_MSG = 'Clinics';
export const DOCTORS_MSG = 'Doctors';
export const DELETED_MSG = 'Deleted';
export const NO_LONGER_ACTIVE = 'No Longer Active';
export const PROVIDER_LIST_MSG = 'My Doctor';
export const INVALID_PATIENT_MSG = 'Invalid patient';
export const PATIENTS_MSG = 'Patients';
export const PRICE_MSG = 'Price';
export const CONTACT_US_TO_UPGRADE_HEADER_MSG =
  'Contact us to upgrade your account!';
export const EMAIL_US_MSG = 'Email Us';
export const CALL_US_MSG = 'Call Us';
export const OPEN_CHAT_MSG = 'Open Chat';

export const PROMO_CODES_MSG = 'Invitation Codes';
export const ALGORITHM_COEFFICIENTS_MSG = 'Algorithm Coefficients';
export const ADD_NEW_MSG = 'Add new';
export const PROMO_CODE_VALID_MSG = 'Invitation code valid';
export const PROMO_CODE_VALIDATION_ERROR_MSG =
  'Invitation code validation error';
export const GET_INVITATION_SMARTLINK_MSG = 'Get invitation Smartlink';
export const CONTACT_PROVIDER_MSG =
  'Please contact your doctor for an invite code';
export const GET_CLINIC_SMARTLINK_MSG = 'Get clinic Smartlink';
export const GET_SHORT_SMARTLINK_MSG = 'Get invitation smartlink';
export const VOLUME_MSG = 'Volume';
export const TESTS_MSG = 'Tests';
export const LAST_TEST_MSG = 'Last Test';
export const BILLING_PAST_DUE_MSG = 'Last billed date';
export const PAYMENT_SUBSCRIPTION_COL_HEADER = 'S';
export const ENROLLED_SUBSCRIPTION_COL_HEADER = 'D/C';
export const MANUAL_SUBSCRIPTION_COL_HEADER = 'I';
export const DOWNLOAD_MOBILE_APP_INFO =
  'Click here to open or download MenHealth® Uroflowmetry App to take your first uroflow test.';
export const HISTORY_CARD_MODAL_INFO_MSG =
  'Table below contains an information about the normal, equivocal and low maximum flow values. It will help you to interpret your test results. If you have any questions or concerns about the test results, please contact your primary care physician (PCP) or urologist for results interpretation.';
export const MAX_FLOW_MSG = 'Max. Flow';
export const AVG_FLOW_MSG = 'Avg. Flow';
export const NEW_PATIENT_MSG = 'Add new patient';
export const IMPORT_NEW_PATIENTS_MSG = 'Import new patients';
export const CHOOSE_FILE_MSG = 'Choose file';
export const UPLOAD_FILE_MSG = 'Upload file';
export const VIEW_IMPORT_RESULTS = 'View imported patients';
export const SEND_MSG = 'Send';
export const CREATE_MSG = 'Create';
export const MY_PATIENTS_MSG = 'My patients';
export const PENDING_MSG = 'Pending';
export const SEARCH_MSG = 'Search';
export const NEW_PATIENTS_MSG = 'New patients';
export const GENERATE_REPORT_MSG = 'Generate report';
export const GENERATE_PATIENTS_ANALYTICS_MSG = 'STATS BY PATIENTS';
export const MY_PATIENT_PROFILE_MSG = 'My patient profile';
export const PATIENT_PROFILE_MSG = 'Patient profile';
export const DOCTORS_MAX_NUMBER = 'Number of doctors';
export const WRITE_MESSAGE_MSG =
  'Write a message. Press Enter to send the message. Press Shift+Enter to enter a new line.';
export const GO_TO_CHAT = 'Go to chat';
export const FLOW_ML_SEC_MSG = 'Flow (mL/sec)';
export const VOLUME_ML_MSG = 'Volume (mL)';
export const AUG_FLOW_ML_MSG = 'Avg. Flow (mL/sec)';
export const MAX_FLOW_ML_MSG = 'Max. Flow (ml/sec)';
export const VOIDING_TIME_MSG = 'Voiding time (sec)';
export const NO_REPORTS_MSG = 'No reports were found';
export const NO_TEST_RESULTS_FOUND_MSG =
  'No test results found for the selected date period';
export const NO_TEST_RESULTS_MSG =
  'Please download and use the MenHealth App to take your first uroflow test';
export const FORGOT_PASSWORD_MSG = 'Forgot password';
export const CHANGE_PASSWORD_MSG = 'Change password';
export const DELETE_ACCOUNT_MSG = 'Delete your account';
export const DELETE_ACCOUNT_ERROR_MSG = 'Deleting account error';
export const ACCOUNT_DELETED_SUCCESSFULLY_MSG = 'Account successfully deleted';
export const UPDATE_PROFILE_ERROR_MSG = 'Updating profile error';
export const FORGOT_PASSWORD_ENTER_EMAIL_MSG =
  'Please enter your e-mail address and we’ll send you instructions to reset your password';
export const RESET_PASSWORD_SUCCESSFUL = 'Your password has been reset';
export const USE_THIS_FORM_TO_CHANGE_PASSWORD_MSG =
  'Please use this form to change your password';
export const OK_MSG = 'Ok';
export const CHECK_YOUR_EMAIL_MSG = 'Please, check your email address';
export const SAVE_PDF_MSG = 'Save PDF';
export const AUG_FLOW_MSG = 'Avg. Flow';
export const TIME_TO_MAX_FLOW_MSG = 'Time to Max. Flow';
export const FLOW_TIME_MSG = 'Flow Time';
export const HESITANCY_TIME_MSG = 'Hesitancy Time';
export const ML_SEC_MSG = 'ml/sec';
export const ML_MSG = 'ml';
export const SEC_MSG = 'sec';
export const YOUR_PROFILE_DATA_HAS_BEEN_CHANGED_MSG =
  'Your profile data has been successfully updated';
export const YOUR_PASSWORD_HAS_BEEN_CHANGED_MSG =
  'Your password has been successfully updated';
export const OLD_PASSWORD_MSG = 'Old password';
export const NEW_PASSWORD_MSG = 'New Password';
export const PREV_MSG = 'Prev';
export const NEXT_MSG = 'Next';
export const CHOOSE_A_PERIOD_MSG = 'Choose a period';
export const CUSTOM_RANGE_MSG = 'Custom range';
export const RANGE_MSG = 'Range';
export const CHART_AREA_MSG = 'Area at the chart';
export const MAX_FLOW_RATE_MSG = 'MaxFlow rate (mL/sec)';
export const NORMAL_MSG = 'Normal';
export const ABOVE_GREEN_LINE_MSG = 'Above the green line';
export const LESS_THEN_FIFTEEN_MSG = '> 15';
export const EQUIVOCAL_MSG = 'Equivocal';
export const BETWEEN_GREEN_AND_RED_LINES_MSG = 'Between green and red lines';
export const BETWEEN_TEEN_AND_FIFTEEN_MSG = '10 - 15';
export const BELOW_RED_LINE = 'Below the red line';
export const LESS_THEN_TEEN_MSG = '< 10';
export const LOW_MSG = 'Low';
export const FROM_MSG = 'from';
export const TO_MSG = 'to';
export const CLOSE_MSG = 'Close';
export const DELETE_PATIENT_MSG = 'Delete patient';
export const SIGNUP_PAGE_TITLE_MSG = 'MenHealth - Sign Up';
export const SIGNIN_PAGE_TITLE_MSG = 'MenHealth - Sign In';
export const ROLE_PAGE_TITLE_MSG = 'MenHealth - Choose your role';
export const PRIVACY_POLICY_MSG = 'Privacy Policy';
export const HIPAA_DISCLAIMER_MSG = 'HIPAA Disclaimer';
export const TERMS_AND_CONDITIONS_MSG = 'Terms and Conditions';
export const PLANS_PAGE_TITLE_MSG = 'Plans';
export const IMPORT_PATENTS_TITLE_MSG = 'Upload your patients';
export const SEND_INVITES_TO_IMPORTED_PATIENTS_MSG =
  'Send invites to imported patients';
export const LOADING_PAGE_TITLE_MSG = 'Loading';
export const BACK_MSG = 'Back';
export const PREV_STEP_MSG = 'Previous step';
export const NEXT_STEP_MSG = 'Next step';
export const GET_RIGHTS_MSG = () =>
  `BE Technologies © ${new Date().getFullYear()} | All Rights Reserved`;
export const PATENTS_MSG =
  'U.S. Patents 8,276,465; 8,567,258; 9,084,571; 11,207,012';
export const CONTACT_MAIL_ADDRESS_MSG = 'San Francisco, CA 94132, USA';
export const ALL_TAX_INCLUDED_MSG = 'All Tax Included';
export const TOTAL_AMOUNT_MSG = 'Total Amount';
export const CONTACT_PHONE = '+1 415 307 8531';
export const APP_EMAIL_MSG = 'Email us';
export const APP_CALL_US = 'Call us';
export const HISTORY_TIMELINE_MSG = 'History Timeline';
export const ORGANIZATION_MSG = 'Organization';
export const TITLE_MSG = 'Title';
export const MESSAGE_MSG = 'Message';
export const SUBMIT_MSG = 'Submit';
export const NAME_MSG = 'Name';
export const ADD_NEW_DOCTOR_MSG = 'Add new doctor';
export const ADD_NEW_CLINIC_MSG = 'Add new clinic';
export const CLINIC_NAME_MSG = 'Clinic name';
export const ADDRESS_MSG = 'Address';
export const PLANS_MSG = 'Plans';
export const FEE_MSG = 'Pricing';
export const SPECIALTY_MSG = 'Specialty';
export const WEBSITE_MSG = 'Website';
export const NO_PROVIDERS_MSG = 'You have no affiliations with a doctor yet.';
export const NO_CLINICS_MSG =
  'You are currently not affiliated with any clinics';
export const NO_DIARY_RESULT_MSG =
  'You will be able to see your urinary diary test results once you download the MenHealth mobile application and start taking Uroflowmetry tests.';
export const FEE_DOCTOR_MSG = 'Pricing for each Doctor';
export const FEE_CLINIC_MSG = 'Pricing for Clinic Subscription';
export const OFFER_CLINIC_SUBSCRIPTION = 'Offer clinic subscription';
export const CANCEL_CLINIC_SUBSCRIPTION_OFFER = 'Cancel subscription offer';
export const CLINIC_NOT_ACCEPTED_SUBSCRIPTION_YET =
  'The subscription is awaiting clinic confirmation.';
export const CLINIC_PRICE_NOT_SET =
  'The subscription price has not yet been set.';
export const CLINIC_HAS_TRIAL_SUBSCRIPTION =
  'The clinic has been provided with a trial subscription';

export const CLINIC_ALREADY_HAVE_SUBSCRIPTION =
  "The clinic can't switch to the free trial. The clinic already has a paid subscription or offer. The limits for doctors and patients can't be changed now.";
export const CAN_INVITE_BEFORE_MSG = 'You can invites before';
export const NEXT_PAYMENT_DATE = 'Payment Date';
export const NO_PAYMENT_DATE = 'No Next Payment Date';
export const SELECT_MEDICATIONS = 'Select medications';
export const SELECT_PROCEDURES = 'Select procedures';
export const ADMIN_MSG = 'Admin';
export const NO_FEE_MSG = 'No Pricing';
export const NO_SET_DOCTOR_MSG = 'Unassigned';
export const NO_ADMIN_MSG = 'No Admin';
export const ADMINISTRATOR_MSG = 'Administrator';
export const NEW_DOCTOR_MSG = 'New doctor';
export const SET_PASSWORD_MSG = 'Set Password';
export const SUBSCRIPTIONS_MSG = 'Subscriptions';
export const ACTIVE_AND_ALL_MSG = 'Active / All';
export const MEDICINES_MSG = 'Medicines';
export const PROCEDURES_MSG = 'Procedures';
export const PROFILE_DATA_HAS_BEEN_CHANGED_MSG =
  'Profile data has been successfully updated';
export const DOCTOR_PROFILE_MSG = 'Doctor profile';
export const APPROVE_INVITE_MSG = 'Approve invite';
export const IM_MSG = 'IM';
export const GROUP_NAME_MSG = 'Group name';
export const ADD_MEDICINE_MSG = 'Add medicine';
export const ADD_PROCEDURE_MSG = 'Add procedure';
export const ADD_COEFFICIENTS_MSG = 'Add coefficients';
export const EDIT_COEFFICIENTS_MSG = 'Edit coefficients';
export const EDIT_PROCEDURE_MSG = 'Edit procedure';
export const LAST_BILLED_DATE_MSG = 'Last billed date';
export const SELECTED_MEDICATIONS_MSG = 'Selected medications';
export const SELECTED_PROCEDURES_MSG = 'Selected procedures';
export const EDIT_PROMO_CODE_MSG = 'Edit promo code';
export const ENTER_PROMO_CODE_INFO_HERE_MSG =
  'Please enter promo code information here';
export const ENTER_PHYSICIAN_INFO_HERE_MSG =
  "Please enter physician's information here";
export const ENTER_PATIENT_INFO_HERE_MSG =
  "Please enter patient's information here";
export const ENTER_CLINIC_INFO_HERE_MSG =
  "Please enter clinic's information here";
export const ENTER_DOCTOR_INFO_HERE_MSG = `Please enter doctor's information here`;
export const PATIENT_REGISTERED_MSG =
  'This patient has been already registered';
export const CLINIC_REGISTERED_MSG = 'This clinic has been already registered';
export const AUA_BPH_SSI_QUESTIONNAIRE_MSG = 'AUA BPH SSI Questionnaire';
export const AUA_BPH_SSI_MSG =
  'American Urological Association BPH Symptom Score Index';
export const START_MSG = 'Start';
export const SYMPTOM_SCORE_MSG = 'Symptom score';
export const BPH_SSI_MSG = 'Your BPH Symptom Score Index';
export const BPH_SSI_EXTERNAL_MSG = 'BPH Symptom Score Index';
export const QUALITY_OF_LIFE_INDEX_MSG = 'Your Quality of life Score Index';
export const QUALITY_OF_LIFE_INDEX_EXTERNAL_MSG = 'Quality of life Score Index';
export const CONTACT_WITH_PCP_OR_UROLOGIST_MSG =
  'Please contact your Primary Care Physician (PCP) or Urologist for the results interpretation.';
export const RETRY_MSG = 'Retry';
export const GENERATE_PDF_MSG = 'Generate PDF';
export const YOU_HAVE_NO_CHATS_MSG = 'You have no chats';
export const YOU_HAVE_NO_MESSAGES_MSG = 'You have no messages';
export const NO_HAVE_MESSAGES_IN_CHAT = 'No messages';
export const CONTACT_ADMIN_MSG = 'Message Support';
export const CONTACT_DOCTOR_MSG = 'Message Doctor';
export const CONTACT_CLINIC_MSG = 'Message Clinic';
export const DELETE_MSG = 'Delete';
export const CONFIRM_DELETION = 'Confirm deletion';
export const WARNING_DELETE_MSG =
  'I understand the consequences, delete my account';
export const NOT_UNDO_ACTION_MSG = 'This action cannot be undone!';
export const DEACTIVATE_MSG = 'Deactivate';
export const ACTIVATE_MSG = 'Activate';
export const CONFIRM_CHANGE = 'Confirm change';
export const DELETE_DOCTOR_MSG = 'Delete doctor';
export const BPH_SCORE_MSG = 'BPH Score';
export const NO_BPH_PATIENT_RESULTS_MSG =
  'The patient has not filled out the questionnaire yet.';
export const NP_BPH_CONTACT_PATIENT_MSG = 'Please contact him';
export const HERE_MSG = 'here';
export const SELECT_RESULTS_MSG = 'Select results';
export const WARNING_ZERO_REPORTS_GENERATE_MSG = `Select reports first or deselect "${SELECT_RESULTS_MSG}" button`;
export const WARNING_NO_HAVE_TEST_REPORTS_GENERATE_MSG =
  "You don't have any tests. Please take a test to generate the report.";
export const PATIENT_LIST_MSG = 'Patient list';
export const SELECT_PLACEHOLDER = 'Select';
export const UPLOAD_PATIENTS_REQUIRED_FIELDS = [
  'Firstname',
  'Lastname',
  'DateOfBirth (use format: mm.dd.yyyy)',
  'MedicalId',
  'Phone',
  'Email',
];
export const UPLOAD_PATIENTS_DATA_FORMAT_EXAMPLE =
  'Imported CSV data file format example (click to view)';
export const UPLOAD_PATIENTS_FORMAT_FILE =
  'First name;Last name;Date of Birthday;Phone;Email;Medical Id\n' +
  'John;Doe;10.12.1987;112321;johndoe@gmail.com;1\n' +
  'Richard;Green;03.04.1988;112321;richardgreen@gmail.com;2\n';
export const START_DATA_IMPORT = 'Start data import';
export const WAITING_FOR_OPERATION = 'File uploaded successfully';
export const PATIENTS_SEND_INVITES_RESULT = 'Patients send invite results';
export const SEND_PATIENTS_INVITES = 'Send invites';
export const START_NEW_IMPORT = 'Start new import';
export const VIEW_PREPARED_PATIENTS_INVITES = 'View prepared invites';
export const SENDING_PATIENTS_INVITES = 'Sending patients invites';
export const VIEW_INVITES_RESULTS = 'View invites results';
export const CANCEL_IMPORT_PATIENTS = 'Cancel';
export const BACK_TO_PATIENTS = 'Go to Patients list';
export const CHOOSE_FILE_TO_IMPORT =
  'Drop or choose a file to send (*.сsv files only)';
export const TOTAL_PATIENTS_NUMBER_IMPORTED = (number: Number) =>
  `${number} patients were imported`;
export const GET_INVALID_PATIENT_RECORDS_NUMBER = (number: Number) =>
  `${number} patients have invalid data`;
export const GET_VALID_PATIENT_RECORDS_NUMBER = (number: Number) =>
  `${number} patients were recognized in this file`;
export const LINK_TO_IMPORT_RESULTS = 'View import log';
export const GET_SENT_INVITES_PATIENT_NUMBER = (number: Number) =>
  `${number} invites sent successfully`;
export const GET_CREATED_INVITES_PATIENT_NUMBER = (number: Number) =>
  `${number} invites created successfully`;
export const GET_ALREADY_EXIST_INVITES_NUMBER = (number: Number) =>
  `${number} invites already exist`;
export const GET_ALREADY_EXIST_PATIENT_NUMBER = (number: Number) =>
  `${number} patients already exist`;
export const GET_IMPORT_PATIENT_PROGRESS = (processed: Number, total: Number) =>
  `${processed} of ${total} patient invites processed`;
export const FAILED_TO_SEND_PATIENT_INVITES =
  'There were some errors during the sending invites';
export const CONFIRM_CANCEL_PATIENT_IMPORT =
  'Are you sure to cancel patients import?';
export const CONFIRM_START_NEW_IMPORT =
  'Import completed. Are you sure to start new import?';
export const CONFIRM_SEND_PATIENT_SEND_INVITES =
  'Are you sure to send patient invites?';
export const CONFIRM_BACK_TO_PATIENTS =
  'Are you sure you want to clear import results and return back to the Patient list?';

export const UPLOAD_PATIENTS_NULLABLE_FIELDS = ['AdditionalInfo'];
export const ABSENT_MSG = 'Absent';
export const SET_MANUAL_SUBSCRIPTION_MSG = (setManualSubscription: boolean) =>
  setManualSubscription ? 'Enroll on free trial' : 'Cancel enrolled free trial';
export const GET_CUSTOM_PLAN_DEFAULT_DESCRIPTION = (maxPatients: number) => {
  return `Unlimited Uroflow Tests --${maxPatients} MenHealth Uroflowmeters --Physician Dashboard --Real Time Patient Data --HIPAA Messenger --Exportable Patient Reports`;
};
export const DOCTOR_CUSTOM_PLAN_HEADER =
  'MenHealth Physician Subscription Plan';
export const CLINIC_CUSTOM_PLAN_HEADER = 'MenHealth Clinic Subscription Plan';

export const GET_SMARTLINK_DOCTOR_MSG = (
  isUnavailable: boolean,
  isAvailableAddPatient?: boolean,
  firstName?: string,
  lastName?: string,
) => {
  if (isUnavailable) {
    return 'Your doctor has not been found. You can fill out the registration form as an independent user';
  }

  if (!!isAvailableAddPatient === false) {
    return 'This registration link is no longer active. Please contact your provider to get the new link or the invitation code.';
  }

  return `Invited by Dr. ${firstName} ${lastName}`;
};

export const GET_SMARTLINK_CLINIC_MSG = (
  isUnavailable: boolean,
  isAvailableAddPatient?: boolean,
  name?: string,
) => {
  if (isUnavailable) {
    return 'Your clinic has not been found. You can fill out the registration form as an independent user';
  }

  if (!!isAvailableAddPatient === false) {
    return 'This registration link is no longer active. Please contact your provider to get the new link or the invitation code.';
  }

  return `Invited by Clinic ${name}`;
};

export const subscriptionHostLocalisation: { [key: string]: string } = {
  WEB: 'Web',
  ANDROID: 'Play store',
  IOS: 'Apple',
};
export const PATIENT_SUBSCRIPTION_COL_DESCRIPTION = (
  isHave: boolean,
  paymentDate?: Date,
  subscriptionHost?: string,
  isSubscriptionActive?: boolean,
) => {
  if (!isHave) {
    return 'invalid';
  }
  const chunks = [] as string[];
  if (isSubscriptionActive) {
    chunks.push(
      `payment subscription${
        !paymentDate
          ? ''
          : `, next payment date: ${format(
              paymentDate,
              CUSTOM_DATE_FNS_FORMAT,
            )}`
      }`,
    );
  }
  if (!isSubscriptionActive) {
    chunks.push(
      `subscription canceled${
        !paymentDate
          ? ''
          : `, expiration date date: ${format(
              paymentDate,
              CUSTOM_DATE_FNS_FORMAT,
            )}`
      }`,
    );
  }
  if (subscriptionHost) {
    chunks.push(
      `platform: ${subscriptionHostLocalisation[subscriptionHost] || ''}`,
    );
  }
  return chunks.join(', ');
};
export const ENROLLED_SUBSCRIPTION_COL_DESCRIPTION = (isHave: boolean) =>
  `${isHave ? 'valid' : 'invalid'} enrolled subscription`;
export const MANUAL_SUBSCRIPTION_COL_DESCRIPTION = (
  isHave: boolean,
  expireDate?: Date,
) =>
  `${isHave ? 'valid' : 'invalid'} manual subscription${
    !expireDate ? '' : ` until ${format(expireDate, CUSTOM_DATE_FNS_FORMAT)}`
  }`;
export const MANUAL_SUBSCRIBED_MSG = 'Enrolled on free trial';
export const SIGNUP_USERS_MSG = 'Registered users';
export const CAPACITY_TO_USE_MSG = 'Free capacity';
export const ACTIVE_SUBSCRIPTIONS_MSG = 'Active subscriptions';
export const INACTIVE_SUBSCRIPTIONS_MSG = 'Inactive subscriptions';
export const NO_TEST_MSG = 'No Tests';
export const DECLINE_INVITE_MSG = 'Decline invite';
export const MESSAGE_WAS_SENT_MSG = 'Message Was Sent';
export const FAILURE_SENT_MESSAGE_MSG = 'Failure Send Message';
export const UNAVAILABLE_DOCTOR_FOR_DEACTIVATE =
  'You can not deactivate doctor, because they unavailable for this relation';
export const DOCTOR_PATIENTS_LIMIT_REACHED = `You have exceeded your allotted number of patient invites. To invite more patients, please upgrade to the next plan`;
export const CLINIC_DOCTORS_LIMIT_REACHED = `You have exceeded your allotted number of doctor invites. To invite more doctors, please upgrade to the next plan`;
export const CURRENT_ACTIVE_SUBSCRIPTION_MSG = 'Current subscription plan: ';
export const SUBSCRIPTION_HOST_MSG = 'Subscription platform: ';
export const TO_MANAGE_APPLE_SUBSCRIPTION_USE_APP_STORE_MSG =
  'Please use App Store to manage your subscription';
export const EXPIRES_ON_MSG = 'Expires on: ';
export const NEXT_PAYMENT_DATE_ON_MSG = 'Next payment date on: ';
export const CAN_NOT_CHANGE_PASSWORD_MSG = 'Can not change password';
export const PROVIDER_SUBSCRIPTION_BLOCKED_MSG =
  'Access to your account has been blocked because your provider subscription has been stopped.';
export const SUBSCRIPTION_BLOCKED_MSG =
  'Access to your account has been blocked because your subscription payment did not go through.';
export const SUBSCRIPTION_PENDING_MSG =
  'To access your account, please confirm your subscription.';

export const PLEASE_UPDATE_PAYMENT_METHOD_MSG =
  'Please update your payment method to reinstate your account and resume subscription.';
export const PROMO_CODE_COPIED_MSG = (code: string) =>
  `Code ${code} copied to clipboard`;
export const SMARTLINK_COPIED_MSG = (firstName: string, lastName: string) =>
  `Smartlink for Dr.${firstName} ${lastName} copied to clipboard`;
export const CLINIC_SMARTLINK_COPIED_MSG = (name: string) =>
  `Smartlink for clinic ${name} copied to clipboard`;

export const ADDON_PRICE_MSG = (
  addOn: AdditionalPaymentPlanDataType,
  plan: PaymentPlanType,
): string =>
  `${ONLY_PRICE_MSG(plan)} + ${priceCurrencyToString(
    addOn.amount,
    plan.currency,
  )} ${addOn.name}`;
export const ONLY_PRICE_MSG = (plan: PaymentPlanType): string =>
  BUY_NOW_SUB_MSG(priceCurrencyToString(plan.price, plan.currency));
export const BUY_NOW_MSG = (price: string): string =>
  `Buy now for ${price}/month`;
export const BUY_NOW_SUB_MSG = (price: string): string => `${price}/month`;
export const CANNOT_STOP_SUBSCRIPTION = (untilDate: Date): string =>
  `You cannot downgrade or cancel your subscription plan during the first 6 months of your subscription. (Until ${format(
    untilDate,
    CUSTOM_DATE_FNS_FORMAT,
  )})`;
export const CLINIC_SUBSCRIPTION_CANCELED =
  'Clinic subscription renewal has been canceled. Please contact us.';
export const CONFIRM_CHANGE_ACTIVATION_FOR_CLINIC_MSG = (
  clinicName: string,
  currentStatus: boolean,
): string =>
  `Are you sure you want to ${newActivateDeactivate(
    currentStatus,
  )} clinic ${clinicName}?`;
export const SUCCESSFULLY_CHANGED_ACTIVATION_FOR_CLINIC_MSG = (
  clinicName: string,
  currentStatus: boolean,
): string =>
  `Successfully ${newActivateDeactivate(currentStatus)} clinic ${clinicName}`;
export const FAILURE_CHANGED_ACTIVATION_FOR_CLINIC_MSG = (
  clinicName: string,
  currentStatus: boolean,
): string =>
  `Failure ${newActivateDeactivate(currentStatus)} clinic ${clinicName}`;

export const CONFIRM_CHANGE_ACTIVATION_FOR_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
  currentStatus: boolean,
): string =>
  `Are you sure you want to ${newActivateDeactivate(
    currentStatus,
  )} doctor ${firstName} ${lastName}${
    !currentStatus ? '' : ' and detach their patients'
  }?`;
export const SUCCESSFULLY_CHANGED_ACTIVATION_FOR_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
  currentStatus: boolean,
): string =>
  `Successfully ${newActivateDeactivate(
    currentStatus,
  )} doctor ${firstName} ${lastName}`;
export const FAILURE_CHANGED_ACTIVATION_FOR_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
  currentStatus: boolean,
): string =>
  `Failure ${newActivateDeactivate(
    currentStatus,
  )} doctor ${firstName} ${lastName}`;

export const CONFIRM_CHANGE_ACTIVATION_FOR_PATIENT_MSG = (
  firstName: string,
  lastName: string,
  currentStatus: boolean,
): string =>
  `Are you sure you want to ${newActivateDeactivate(
    currentStatus,
  )} patient ${firstName} ${lastName}?`;
export const SUCCESSFULLY_CHANGED_ACTIVATION_FOR_PATIENT_MSG = (
  firstName: string,
  lastName: string,
  currentStatus: boolean,
): string =>
  `Successfully ${newActivateDeactivate(
    currentStatus,
  )} patient ${firstName} ${lastName}`;
export const FAILURE_CHANGED_ACTIVATION_FOR_PATIENT_MSG = (
  firstName: string,
  lastName: string,
  currentStatus: boolean,
): string =>
  `Failure ${newActivateDeactivate(
    currentStatus,
  )} patient ${firstName} ${lastName}`;

export const CONFIRM_CHANGE_ACTIVATION_PROMO_CODE_MSG = (
  promoCode: string,
  currentStatus: boolean,
): string =>
  `Are you sure you want to ${newActivateDeactivate(
    currentStatus,
  )} promo code ${promoCode}?`;
export const CONFIRM_CHANGE_PROMO_CODE_GIVES_SUBSCRIPTION_MSG = (
  promoCode: string,
  givesSubscription: boolean,
): string =>
  `Using the ${promoCode} promocode will ${
    givesSubscription ? 'provide' : 'not provide'
  } subscriptions to patients. Please confirm the change`;
export const SUCCESSFULLY_CHANGED_ACTIVATION_PROMO_CODE_MSG = (
  promoCode: string,
  currentStatus: boolean,
): string =>
  `Successfully ${newActivateDeactivate(
    currentStatus,
  )} promo code ${promoCode}`;
export const SUCCESSFULLY_CHANGED_PROMO_CODE_GIVES_SUBSCRIPTION_FLAG_MSG = (
  promoCode: string,
  givesSubscription: boolean,
): string =>
  `Success. Using the ${promoCode} will ${
    givesSubscription ? 'provide' : 'not provide'
  } subscriptions to patients`;
export const FAILURE_CHANGED_ACTIVATION_PROMO_CODE_MSG = (
  promoCode: string,
  currentStatus: boolean,
  error: Error,
): string =>
  `Failure ${newActivateDeactivate(
    currentStatus,
  )} promo code ${promoCode}: ${messageFromError(error)}`;

export const FAILURE_CHANGED_PROMO_CODE_GIVES_SUBSCRIPTION_FLAG_MSG = (
  promoCode: string,
): string => `Failed to change ${promoCode} gives subscription flag`;

export const FAILED_CHANGE_PAYMENT_PLAN_MSG = (error: Error): string =>
  `Can not change the plan: ${messageFromErrorChangePayment(error)}`;

export const DELETE_PROMO_CODE_CONFIRM_MSG = (promoCode: string): string =>
  `Are you sure delete promo code ${promoCode}?`;
export const SUCCESSFULLY_DELETED_PROMO_CODE_CONFIRM_MSG = (
  promoCode: string,
): string => `Promo code ${promoCode} successfully deleted`;
export const FAILURE_DELETED_PROMO_CODE_CONFIRM_MSG = (
  promoCode: string,
  error: Error,
): string =>
  `Failure to delete promo code ${promoCode}: ${messageFromError(error)}`;
export const SUCCESSFULLY_EDIT_PROMO_CODE_CONFIRM_MSG = (
  promoCode: string,
): string => `Promo code ${promoCode} successfully edited`;
export const FAILURE_EDIT_PROMO_CODE_CONFIRM_MSG = (
  promoCode: string,
  error: Error,
): string =>
  `Failure to edit promo code ${promoCode}: ${messageFromError(error)}`;

export const CONFIRM_EMAIL_MSG = (email: string): string =>
  `Please type the email address (${email}) linked with your account to confirm.`;
export const DELETE_ACCOUNT_CONFIRM_MSG =
  'This will permanently delete your account, subscriptions, messages, profile data, and remove all associations.';
export const DOCTOR_PATIENTS_COUNT_MSG = (patientsCount: number): string =>
  `You have already signed ${patientsCount} patients.`;
export const CANNOT_CHOOSE_PLAN = () =>
  `You can not choose this plan instead of actual\n due to the current patient count`;
export const CONFIRM_CANCEL_SUBSCRIPTION_MSG =
  'Are you sure you want to stop renew subscription?';
export const CONFIRM_CANCEL_CLINIC_SUBSCRIPTION_MSG =
  'Are you sure you want to stop renew current clinic subscription?';
export const CONFIRM_FREE_TRIAL_CLINIC_SUBSCRIPTION_CHANGE_MSG = 'Confirm';
export const CONFIRM_FREE_TRIAL_CLINIC_SUBSCRIPTION_MSG = (
  setManualSubscription: boolean,
) =>
  setManualSubscription
    ? 'Are you sure you want to set free trial subscription?'
    : 'Are you sure you want to stop free trial subscription?';
export const CONFIRM_CREATE_CLINIC_SUBSCRIPTION_MSG =
  'Are you sure you want to offer the designed clinic subscription?';
export const DELETE_CLINIC_CONFIRM_MSG = (clinicName: string): string =>
  `Are you sure delete clinic ${clinicName}?`;
export const DELETE_CLINIC_BY_DOCTOR_CONFIRM_MSG = (
  clinicName: string,
): string =>
  `Are you sure to delete clinic ${clinicName}? ${DATA_WILL_BE_MARKED_AS_DELETED}. Patients will be automatically detached`;
export const DECLINE_CLINIC_INVITE_CONFIRM_MSG = (clinicName: string): string =>
  `Are you sure to decline invite from clinic ${clinicName}`;
export const SUCCESSFULLY_RESEND_CLINIC_INVITE_MSG = (
  clinicName: string,
  email: string,
): string =>
  `The invitation to ${clinicName} (${email}) has been successfully resent.`;
export const DELETE_DOCTOR_CONFIRM_MSG = (
  firstName: string,
  lastName: string,
): string => `Are you sure delete doctor ${firstName} ${lastName}?`;
export const DELETE_PATIENT_CONFIRM_MSG = (
  firstName: string,
  lastName: string,
): string => `Are you sure delete patient ${firstName} ${lastName}?`;
export const DELETE_PATIENT_FROM_CLINIC_PATIENTS_LIST_CONFIRM_MSG = (
  firstName: string,
  lastName: string,
): string =>
  `Are you sure you want to remove patient ${firstName} ${lastName} from the clinic's patient list?`;
export const SUCCESSFULLY_DELETE_CLINIC_MSG = (clinicName: string): string =>
  `Successfully deleted clinic ${clinicName}`;
export const FAILURE_DELETE_CLINIC_MSG = (clinicName: string): string =>
  `Failure delete clinic ${clinicName}`;
export const SUCCESSFULLY_DELETE_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
): string => `Successfully deleted doctor ${firstName} ${lastName}`;
export const FAILURE_DELETE_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
): string => `Failure delete doctor ${firstName} ${lastName}`;
export const SUCCESSFULLY_DELETE_PATIENT_MSG = (
  firstName: string,
  lastName: string,
): string => `Successfully deleted patient ${firstName} ${lastName}`;
export const FAILURE_DELETE_PATIENT_MSG = (
  firstName: string,
  lastName: string,
): string => `Failure delete patient ${firstName} ${lastName}`;
export const SUCCESSFULLY_INVITE_CLINIC_MSG = (clinicName: string): string =>
  `Successfully invited clinic ${clinicName}`;
export const FAILURE_INVITE_CLINIC_MSG = (clinicName: string): string =>
  `Failure invite clinic ${clinicName}`;
export const SUCCESSFULLY_INVITE_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
): string => `Successfully invited doctor ${firstName} ${lastName}`;
export const FAILURE_INVITE_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
): string => `Failure invite doctor ${firstName} ${lastName}`;
export const SUCCESSFULLY_EDIT_CLINIC_MSG = (clinicName: string): string =>
  `Successfully edit clinic ${clinicName}`;
export const FAILURE_EDIT_CLINIC_MSG = (clinicName: string): string =>
  `Failure edit clinic ${clinicName}`;
export const SUCCESSFULLY_EDIT_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
): string => `Successfully edit doctor ${firstName} ${lastName}`;
export const FAILURE_EDIT_DOCTOR_MSG = (
  firstName: string,
  lastName: string,
): string => `Failure edit doctor ${firstName} ${lastName}`;

export const CONFIRM_DEACTIVATE_DOCTOR_MSG = (doctor: DoctorType): string =>
  `Are you sure you want to deactivate the doctor ${doctor.firstName} ${doctor.lastName} and detach his patients?`;
export const CONFIRM_ACTIVATE_DOCTOR_MSG = (doctor: DoctorType): string =>
  `Are you sure you want to activate the doctor ${doctor.firstName} ${doctor.lastName}?`;

export const CONFIRM_DELETE_DOCTOR_BY_CLINIC_MSG = (
  doctor: DoctorType,
): string =>
  `Are you sure you want to delete doctor ${doctor.firstName} ${doctor.lastName} from the clinic's list of doctors and detach his patients?`;

export const CONFIRM_DEACTIVATE_PATIENT_MSG = (patient: {
  firstName: string;
  lastName: string;
}): string =>
  `Are you sure to deactivate patient ${patient.firstName} ${patient.lastName}?`;
export const SUCCESSFULLY_DEACTIVATE_PATIENT_MSG = (patient: {
  firstName: string;
  lastName: string;
}): string =>
  `Successfully deactivated patient ${patient.firstName} ${patient.lastName}`;
export const FAILURE_DEACTIVATE_PATIENT_MSG = (
  patient: { firstName: string; lastName: string },
  error: Error,
): string =>
  `Failure deactivated patient ${patient.firstName} ${
    patient.lastName
  }: ${messageFromError(error)}`;

export const GET_RESEND_INVITE_SUCCESS_MSG = (user: {
  firstName: string;
  lastName: string;
  email: string;
}): string =>
  `The invitation to ${user.firstName} ${user.lastName} (${user.email}) has been successfully resent`;
export const GET_RESEND_INVITE_SUCCESS_BY_NAME_MSG = (name: string): string =>
  `The invitation to ${name} has been successfully resent`;

export const USER_ENROLLED_UNDER_PARENT = (name: string) =>
  `You are currently enrolled under ${name} provider plan.`;
export const USER_ENROLLED_ON_FREE_TRIAL = (expireDate?: Date) =>
  `You have a free trial${
    !expireDate
      ? ''
      : ` that will expire on ${format(expireDate, CUSTOM_DATE_FNS_FORMAT)}`
  }.`;
export const SIGN_UP_FOR_SELF_MONITORING_PLAN =
  'Please select the desired subscription plan.';

export const SUCCESSFULLY_EDIT_PATIENT = (patient: {
  firstName: string;
  lastName: string;
}): string =>
  `Successfully edit patient ${patient.firstName} ${patient.lastName}`;
export const FAILURE_EDIT_PATIENT = (
  patient: { firstName: string; lastName: string },
  error: Error,
): string =>
  `Failure edit patient ${patient.firstName} ${
    patient.lastName
  }: ${messageFromError(error)}`;

export const SUCCESSFULLY_INVITE_PATIENT = (patient: {
  firstName: string;
  lastName: string;
}): string =>
  `Successfully invited patient ${patient.firstName} ${patient.lastName}`;
export const FAILURE_INVITE_PATIENT = (
  patient: { firstName: string; lastName: string },
  error: Error,
): string =>
  `Failure invited patient ${patient.firstName} ${
    patient.lastName
  }: ${messageFromError(error)}`;

export const FOLLOW_US = (socialNetwork: string) =>
  `Follow us on ${socialNetwork}`;

export const REQUIRED_FIELD_MSG = (field: string): string => `${field} *`;

export const SUCCESSFULLY_CREATE_PROMO_CODE = (code: string): string =>
  `Successfully created promo code ${code}`;
export const FAILURE_CREATE_PROMO_CODE = (code: string, error: Error): string =>
  `Failure created promo code ${code}: ${messageFromError(error)}`;

export const NEED_AUTHENTICATE_ERROR_MESSAGE =
  'Please Log In again to interact with the platform.';
export const NO_INTERNET_ERROR_MESSAGE =
  'You are offline. Please establish an internet connection and try again.';
export const SUBSCRIPTION_IS_ACTIVE = 'Your subscription plan is active.';
export const DONT_HAVE_SUBSCRIPTION_PATIENT =
  "You don't have an active subscription. Please buy a subscription.";
export const SUBSCRIPTON_IS_EXPIRED_PATIENT =
  'Your subscription plan has expired. Please renew the subscription.';
export const SUBSCRIPTON_IS_EXPIRED =
  'Your subscription plan has expired. Please contact customer support.';
export const DONT_HAVE_SUBSCRIPTION =
  "You don't have an active subscription. Please contact customer support.";

export const PATIENT_NOT_BILLED = 'Not billed';
export const PATIENT_BILLED = 'Billed';
export const PATIENT_BILLED_NO_TESTS = 'Billed (No tests)';
export const PATIENT_NOT_BILLED_NO_TESTS = 'Not billed (No tests)';

export const GET_BILLING_PASTDUE_DESCRIPTION = (
  billingPastDue: Number,
  lastBilledDate: Date,
  lastTestDate: Date,
): string => {
  return billingPastDue === BillStatus.NotBilledAndTests
    ? PATIENT_NOT_BILLED
    : billingPastDue === BillStatus.BillSuccessAndTests
    ? PATIENT_BILLED
    : billingPastDue === BillStatus.BillSuccessAndNoTests
    ? PATIENT_BILLED_NO_TESTS
    : moment(lastBilledDate).from(lastTestDate);
};

export const GET_TESTS_PASTDUE_DESCRIPTION = (
  pastDueReportsCount: Number,
  totalReportsCount: Number,
  lastBilledDate: Date,
): string => `Last billed date: ${
  lastBilledDate
    ? format(new Date(lastBilledDate), CUSTOM_LONG_DATE_FORMAT)
    : 'N/A'
}
  ${
    pastDueReportsCount && totalReportsCount
      ? `Total tests: ${totalReportsCount}, Total past due tests: ${pastDueReportsCount}`
      : !pastDueReportsCount && totalReportsCount
      ? `Total tests: ${totalReportsCount}`
      : !pastDueReportsCount && !totalReportsCount
      ? 'No tests'
      : ''
  }`;
export const EMAILS_ADDRESSES_DO_NOT_MATCH = `Email addresses do not not match`;
export const PLEASE_INPUT_PATIENT_EMAIL = `Please enter the patient's email`;
export const DATA_WILL_BE_DELETED_PERNAMENTLY = `Data will be deleted pernamently`;
export const DATA_WILL_BE_MARKED_AS_DELETED = `Please notice, data will not be removed from the database permanently, but will be marked as deleted`;
export const LOADING_WITH_THREE_DOTS = `LOADING...`;
export const DOWNLOAD = `DOWNLOAD`;
