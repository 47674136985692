import gql from 'graphql-tag';

export const GET_USER_DATA_BY_TOKEN = gql`
  query getUserDataByToken($token: String!) {
    getUserDataByToken(token: $token) {
      role
      email
      signed
    }
  }
`;

export const GET_ADMIN_ID = gql`
  query getAdminId {
    id: getAdminId
  }
`;

export const SAVE_SSI_RESULTS_MUTATION = gql`
  mutation saveSsiResults($payload: [SaveSsiResultsInput]!) {
    saveSsiResults(payload: $payload) {
      id
    }
  }
`;

export const CONTACT_US_MUTATION = gql`
  mutation contactUs($payload: ContactUsInput!) {
    contactUs(payload: $payload)
  }
`;

export const GROUP_CONTACT_US_MUTATION = gql`
  mutation groupContactUs($payload: GroupContactUsInput!) {
    groupContactUs(payload: $payload)
  }
`;

export const CONTACT_US_PATIENT_MUTATION = gql`
  mutation contactUsPatient($payload: ContactUsPatientInput!) {
    contactUsPatient(payload: $payload)
  }
`;

export const DISCONNECT_USER_MUTATION = gql`
  mutation disconnectUser($id: String!) {
    disconnectUser(id: $id) {
      id
    }
  }
`;

export const DEACTIVATE_DOCTOR_BY_PATIENT = gql`
  mutation deactivateDoctorByPatient($relationId: String!) {
    deactivateDoctorByPatient(relationId: $relationId)
  }
`;

export const DEACTIVATE_PATIENT_BY_DOCTOR = gql`
  mutation deactivatePatientByDoctor($patientId: String!) {
    deactivatePatientByDoctor(patientId: $patientId)
  }
`;

export const GET_BLADDER_DIARY_DATES = gql`
  query getBladderDiaryDates($userId: String!, $userOffset: Int!) {
    getBladderDiaryDates(userId: $userId, userOffset: $userOffset)
  }
`;

export const GET_BLADDER_DIARY_DATA = gql`
  query AggregateDailyBladderDiary(
    $userId: String!
    $from: DateTime
    $to: DateTime
    $dayPeriodName: String!
    $timePeriodName: String
    $userOffset: Int!
    $type: [String!]
  ) {
    aggregateBladderDiary(
      userId: $userId
      from: $from
      to: $to
      dayPeriodName: $dayPeriodName
      timePeriodName: $timePeriodName
      userOffset: $userOffset
      type: $type
    ) {
      tableData {
        date
        time
        drinksSummary
        tripsToBathroom
        volumeUrine
        volumeDrinks
        hasLeaks
        history {
          ... on BladderDiaryUroflowmetryReport {
            id
            max
            volume
            average
            flowTime
            type
            urgeLevel
            urineColor
            takenAt
          }
          ... on FluidIntake {
            id
            drinkType
            drinkTypeDescription
            amount
            takenAt
            type
          }
          ... on Leakage {
            id
            leakageVolume: volume
            reason
            note
            takenAt
            type
          }
        }
      }
      statistics {
        maxFlow
        avgFlow
        totalVoidedVolume
        avgVoidedVolume
        largestVoid
        leaksCount
      }
    }
  }
`;

export const UROFLOWMETRY_REPORTS_CONNECTION_QUERY = gql`
  query uroflowmetryReports(
    $where: UroflowmetryReportWhereInput
    $orderBy: UroflowmetryReportOrderByInput
    $skip: Int
    $first: Int
    $dayPeriodName: String
    $userOffset: Int
    $skipZeroItems: Boolean
  ) {
    total: uroflowmetryReportsConnectionCount(
      where: $where
      orderBy: $orderBy
      dayPeriodName: $dayPeriodName
      userOffset: $userOffset
    )
    current: uroflowmetryReportsConnection(
      where: $where
      orderBy: $orderBy
      skip: $skip
      first: $first
      dayPeriodName: $dayPeriodName
      userOffset: $userOffset
      skipZeroItems: $skipZeroItems
    ) {
      id
      max
      volume
      average
      timestamp
      voidingTime
      chartData
    }
  }
`;

export const DOCTORS_FOR_CLINIC = gql`
  query doctors(
    $page: Int
    $pageSize: Int
    $orderBy: DoctorOrderBy
    $search: String!
    $isSigned: Boolean!
  ) {
    aggregate: doctorsCount(search: $search, isSigned: $isSigned)
    current: filterDoctorsForClinic(
      page: $page
      pageSize: $pageSize
      search: $search
      isSigned: $isSigned
      orderBy: $orderBy
    ) {
      id
      email
      firstName
      lastName
      signed
      maxPatients
      invitesBefore
      active
      avatarUrl
      patientsSize
      smartlinkId
    }
  }
`;

export const DELETE_CLINIC_BY_ADMIN = gql`
  mutation deleteClinicByAdmin($clinicId: String!) {
    deleteClinicByAdmin(clinicId: $clinicId)
  }
`;

export const ACTIVATION_CLINIC_BY_ADMIN = gql`
  mutation activationClinicByAdmin($clinicId: String!, $isActive: Boolean!) {
    activationClinicByAdmin(clinicId: $clinicId, isActive: $isActive)
  }
`;

export const RESEND_CLINIC_INVITE = gql`
  mutation resendClinicInvite($clinicId: String!) {
    resendClinicInvite(clinicId: $clinicId)
  }
`;

export const CLINICS_FOR_ADMIN = gql`
  query clinicsForAdmin(
    $page: Int!
    $pageSize: Int!
    $orderBy: ClinicOrderBy
    $search: String!
    $isSigned: Boolean!
  ) {
    aggregate: clinicsCount(search: $search, isSigned: $isSigned)
    current: clinicsForAdmin(
      page: $page
      pageSize: $pageSize
      isSigned: $isSigned
      search: $search
      orderBy: $orderBy
    ) {
      id
      email
      name
      avatarUrl
      phone
      address
      active
      maxDoctors
      isNoLimitDoctors
      maxPatients
      isNoLimitPatients
      nextPaymentDate
      isPatientUnlimitedAccess
      isDoctorUnlimitedAccess
      isActiveInvitesBefore
      invitesBefore
      isManualSubscribed
      manualSubscribedTillDate
      fee {
        basic
        eachPatient
        eachDoctor
        details
      }
      clinicAdmin {
        firstName
        lastName
        title
      }
      doctorsSize
      patientsSize
      planMappingId
      planId
      price
    }
  }
`;

export const FILTER_PATIENTS_FOR_CLINIC_DOCTOR = gql`
  query patientsForClinicDoctor(
    $page: Int!
    $pageSize: Int!
    $search: String!
    $isSigned: Boolean!
    $orderBy: PatientOrderBy
  ) {
    aggregate: patientsCount(search: $search, isSigned: $isSigned)
    current: filterPatientsForClinicDoctor(
      page: $page
      pageSize: $pageSize
      isSigned: $isSigned
      search: $search
      orderBy: $orderBy
    ) {
      id
      email
      firstName
      lastName
      dob
      signed
      medicalID
      phone
      active
      additionalInfo
      avatarUrl
      lastBilledDate
      billingPastDue
      doctors {
        id
        firstName
        lastName
      }
      procedures {
        createdAt
        label
        id
        assignedBy {
          id
          email
        }
        timestamp
      }
      medicines {
        id
        createdAt
        medicine {
          label
          groupName
        }
        assignedBy {
          id
          email
        }
      }
      uroflowmetryReports {
        id
        timestamp
        offset
      }
      totalTestResults
      lastTestDate
      lastTestDateLocalOffset
    }
  }
`;

export const DELETE_DOCTOR_BY_ADMIN = gql`
  mutation deleteDoctorByAdmin($doctorId: String!) {
    deleteDoctorByAdmin(doctorId: $doctorId)
  }
`;

export const UPDATE_DOCTOR_BY_ADMIN = gql`
  mutation updateDoctorByAdmin(
    $avatar: Upload
    $firstName: String
    $lastName: String
    $position: String
    $email: String
    $doctorID: ID!
    $maxPatients: Float
    $phone: String
    $address: String
    $specialty: String
    $clinicName: String
    $webSite: String
    $invitesBefore: DateTime
    $isActiveInvitesBefore: Boolean
    $clinicId: String
    $isNoLimitPatients: Boolean
    $isPatientUnlimitedAccess: Boolean
    $isManualSubscribed: Boolean
    $manualSubscribedTillDate: DateTime
    $planMappingId: String
    $planId: String
    $price: Float
  ) {
    updateDoctorExternal(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      position: $position
      email: $email
      doctorID: $doctorID
      maxPatients: $maxPatients
      phone: $phone
      clinicName: $clinicName
      specialty: $specialty
      webSite: $webSite
      address: $address
      invitesBefore: $invitesBefore
      isActiveInvitesBefore: $isActiveInvitesBefore
      clinicId: $clinicId
      isNoLimitPatients: $isNoLimitPatients
      isPatientUnlimitedAccess: $isPatientUnlimitedAccess
      isManualSubscribed: $isManualSubscribed
      manualSubscribedTillDate: $manualSubscribedTillDate
      planMappingId: $planMappingId
      planId: $planId
      price: $price
    ) {
      id
      email
    }
  }
`;

export const PATIENTS_FOR_ADMIN = gql`
  query patientsForAdmin(
    $page: Int!
    $pageSize: Int!
    $search: String!
    $isSigned: Boolean!
    $orderBy: [PatientOrderBy!]
  ) {
    aggregate: patientsCount(search: $search, isSigned: $isSigned)
    current: filterPatientsForAdmin(
      page: $page
      pageSize: $pageSize
      search: $search
      isSigned: $isSigned
      orderBy: $orderBy
    ) {
      id
      email
      firstName
      lastName
      active
      avatarUrl
      doctors {
        firstName
        lastName
      }
      clinics {
        name
      }
      totalTestResults
      lastTestDate
      lastBilledDate
      dob
      medicalID
      phone
      additionalInfo
      isManualSubscribed
      manualSubscribedTillDate
      lastTestDateLocalOffset
      subscriptionHost
      isSubscriptionActive
      isEnrolledSubscription
      isSubscriptionPayment
      expireSubscriptionDate
      planMappingId
      planId
      price
    }
  }
`;

export const ACTIVATION_PATIENT_BY_ADMIN = gql`
  mutation activationPatientByAdmin($patientId: String!, $isActive: Boolean!) {
    activationPatientByAdmin(patientId: $patientId, isActive: $isActive)
  }
`;

export const ACTIVATION_DOCTOR_BY_ADMIN = gql`
  mutation activationDoctorByAdmin($doctorId: String!, $isActive: Boolean!) {
    activationDoctorByAdmin(doctorId: $doctorId, isActive: $isActive)
  }
`;

export const DOCTORS_FOR_ADMIN_QUERY = gql`
  query doctors(
    $page: Int!
    $pageSize: Int!
    $isSigned: Boolean!
    $orderBy: DoctorOrderBy
    $searchVal: String!
  ) {
    aggregate: doctorsCount(search: $searchVal, isSigned: $isSigned)
    current: filterDoctorsForAdmin(
      page: $page
      pageSize: $pageSize
      isSigned: $isSigned
      orderBy: $orderBy
      search: $searchVal
    ) {
      id
      email
      firstName
      lastName
      phone
      address
      specialty
      webSite
      clinicName
      maxPatients
      invitesBefore
      isNoLimitPatients
      invitesBefore
      isActiveInvitesBefore
      isPatientUnlimitedAccess
      isManualSubscribed
      manualSubscribedTillDate
      active
      avatarUrl
      patientsSize
      clinics
      smartlinkId
      planMappingId
      planId
      price
    }
  }
`;

export const FETCH_PATIENT_QUERY = gql`
  query fetchPatient($patientID: String!) {
    fetchPatient(patientID: $patientID) {
      id
      email
      firstName
      lastName
      role
      dob
      medicalID
      phone
      active
      additionalInfo
      avatarUrl
      lastBilledDate
      doctors {
        id
        firstName
        lastName
        position
        avatarUrl
      }
      procedures {
        createdAt
        label
        id
        assignedBy {
          id
          email
        }
        timestamp
      }
      medicines {
        id
        createdAt
        medicine {
          label
          groupName
        }
        assignedBy {
          id
          email
        }
      }
    }
  }
`;

export const FETCH_DOCTOR_QUERY = gql`
  query fetchDoctor($doctorID: ID!) {
    fetchDoctor(doctorID: $doctorID) {
      id
      email
      firstName
      lastName
      role
      signed
      position
      maxPatients
      invitesBefore
      active
      avatarUrl
    }
  }
`;

export const SSI_DETAILS = gql`
  query ssiDetails($patientID: ID, $ssiId: ID) {
    ssiDetails(patientID: $patientID, ssiId: $ssiId) {
      answers
    }
  }
`;

export const SSI_RESULTS = gql`
  query ssiResultsListQuery(
    $patientId: ID
    $period: SsiQuestionnaireResultPeriodInput
    $orderBy: SsiQuestionnaireResultOrderByInput
    $skip: Int
    $first: Int
  ) {
    ssiResults: ssiResultsList(
      patientId: $patientId
      period: $period
      orderBy: $orderBy
      skip: $skip
      first: $first
    ) {
      ssiResults {
        id
        takenAt
        bphSymptomScore
        qualityOfLife
        answers
      }
      patientFullName
    }
    ssiResultsCount: ssiResultsCount(patientId: $patientId, period: $period)
  }
`;

export const DELETE_UROFLOWMETRY_REPORT_MUTATION = gql`
  mutation deleteUroflowmetryReport($reportID: String!) {
    deleteUroflowmetryReport(reportID: $reportID) {
      id
    }
  }
`;

export const DELETE_SSI_RESULT_MUTATION = gql`
  mutation deleteSsiResult($ssiId: String!) {
    deleteSsiResult(ssiId: $ssiId) {
      id
    }
  }
`;

export const DELETE_DOCTOR_BY_CLINIC = gql`
  mutation deleteDoctorByClinic($doctorId: String!) {
    deleteDoctorByClinic(doctorId: $doctorId)
  }
`;

export const DELETE_PATIENT = gql`
  mutation deletePatient($patientId: String!) {
    deletePatient(patientId: $patientId)
  }
`;

export const CLINICS_FOR_DOCTOR = gql`
  query clinicsForDoctor {
    clinicsForDoctor {
      id
      name
      maxPatient
      isNoLimitPatients
    }
  }
`;

export const DELETE_CLINIC_FOR_DOCTOR = gql`
  mutation deleteClinicForDoctor($clinicId: String!) {
    deleteClinicForDoctor(clinicId: $clinicId)
  }
`;

export const DELETE_PATIENT_CLINIC_DOCTOR = gql`
  mutation deletePatientClinicDoctor($relationId: String!) {
    deletePatientClinicDoctor(relationId: $relationId)
  }
`;

export const DOCTORS_CLINICS_AND_INVITES_FOR_PATIENT = gql`
  query doctorsClinicsInvitesForPatient {
    doctorsClinicsForPatient {
      id
      clinic {
        id
        name
        avatarUrl
        active
        isDeleted
        isDeleted
      }
      doctor {
        id
        firstName
        lastName
        email
        active
        isDeleted
        avatarUrl
      }
    }
    invitationsForPatient {
      id
      clinic {
        id
        name
        avatarUrl
        active
        isDeleted
      }
      doctor {
        id
        firstName
        lastName
        email
        active
        isDeleted
        avatarUrl
      }
    }
    invitationsFromPatient {
      id
      clinic {
        id
        name
        avatarUrl
        active
        isDeleted
      }
      doctor {
        id
        firstName
        lastName
        email
        active
        isDeleted
        avatarUrl
      }
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser(
    $avatar: Upload
    $firstName: String
    $lastName: String
    $position: String
    $dob: DateTime
    $medicalID: String
    $phone: String
    $email: String
    $address: String
    $specialty: String
    $webSite: String
    $clinicName: String
    $enableNotification: Boolean
    $isNewsSubscribed: Boolean
    $procedures: [IUpdateProceduresInput]
    $medicines: [IUpdateMedicinesInput]
  ) {
    updateUser(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      position: $position
      dob: $dob
      medicalID: $medicalID
      phone: $phone
      email: $email
      address: $address
      specialty: $specialty
      webSite: $webSite
      clinicName: $clinicName
      isNewsSubscribed: $isNewsSubscribed
      enableNotification: $enableNotification
      medicines: $medicines
      procedures: $procedures
    ) {
      id
      email
    }
  }
`;

export const IMPORT_PATIENT_MUTATION = gql`
  mutation importPatients($clinicId: ID, $doctorId: ID, $importFile: Upload!) {
    importPatients(
      clinicId: $clinicId
      doctorId: $doctorId
      importFile: $importFile
    ) {
      result
      logFileUrl
      importedRecords {
        dob
        medicalID
        firstName
        lastName
        phone
        email
      }
      fileIds
      batchSize
      validRecordsNumber
      invalidRecordsNumber
      message
    }
  }
`;

export const SEND_PATIENT_INVITES = gql`
  mutation sendPatientInvites(
    $clinicId: ID
    $doctorId: ID
    $importFileId: String!
  ) {
    sendPatientInvites(
      clinicId: $clinicId
      doctorId: $doctorId
      importFileId: $importFileId
    ) {
      result
      invitesSentNumber
      createdInvitesNumber
      invitesAlreadyExistNumber
      patientsAlreadyExistNumber
    }
  }
`;

export const UPDATE_PATIENT_BY_CLINIC_MUTATION = gql`
  mutation updatePatientByClinic(
    $patientId: ID!
    $avatar: Upload
    $firstName: String
    $lastName: String
    $position: String
    $dob: DateTime
    $medicalID: String
    $phone: String
    $email: String
    $additionalInfo: String
    $doctorId: ID
    $lastBilledDate: DateTime
    $procedures: [IUpdateProceduresInput]
    $medicines: [IUpdateMedicinesInput]
  ) {
    updatePatientByClinic(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      position: $position
      lastBilledDate: $lastBilledDate
      dob: $dob
      medicalID: $medicalID
      phone: $phone
      email: $email
      additionalInfo: $additionalInfo
      patientId: $patientId
      doctorId: $doctorId
      medicines: $medicines
      procedures: $procedures
    ) {
      id
    }
  }
`;

export const UPDATE_PATIENT_BY_DOCTOR_MUTATION = gql`
  mutation updatePatientByDoctor(
    $avatar: Upload
    $firstName: String
    $lastName: String
    $position: String
    $dob: DateTime
    $medicalID: String
    $lastBilledDate: DateTime
    $phone: String
    $email: String
    $additionalInfo: String
    $patientId: ID!
    $procedures: [IUpdateProceduresInput]
    $medicines: [IUpdateMedicinesInput]
  ) {
    updatePatientByDoctor(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      position: $position
      lastBilledDate: $lastBilledDate
      dob: $dob
      medicalID: $medicalID
      phone: $phone
      email: $email
      additionalInfo: $additionalInfo
      medicines: $medicines
      procedures: $procedures
      patientId: $patientId
    ) {
      id
    }
  }
`;

export const EDIT_PATIENT_BY_ADMIN_MUTATION = gql`
  mutation editPatientByAdmin(
    $avatar: Upload
    $firstName: String
    $lastName: String
    $position: String
    $dob: DateTime
    $medicalID: String
    $phone: String
    $email: String
    $additionalInfo: String
    $isManualSubscribed: Boolean
    $lastBilledDate: DateTime
    $manualSubscribedTillDate: DateTime
    $patientId: ID!
    $planMappingId: String
    $planId: String
    $price: Float
  ) {
    updatePatientByAdmin(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      position: $position
      lastBilledDate: $lastBilledDate
      dob: $dob
      medicalID: $medicalID
      phone: $phone
      email: $email
      additionalInfo: $additionalInfo
      patientId: $patientId
      isManualSubscribed: $isManualSubscribed
      manualSubscribedTillDate: $manualSubscribedTillDate
      planMappingId: $planMappingId
      planId: $planId
      price: $price
    ) {
      id
    }
  }
`;

export const UPDATE_CLINIC_BY_ADMIN_MUTATION = gql`
  mutation editClinicByAdmin(
    $clinicId: String!
    $name: String
    $email: String
    $phone: String
    $address: String
    $maxDoctors: Int
    $isNoLimitDoctors: Boolean
    $maxPatients: Int
    $isNoLimitPatients: Boolean
    $fee: FeeClinic
    $admin: AdminForClinic
    $avatar: Upload
    $nextPaymentDate: DateTime
    $isPatientUnlimitedAccess: Boolean
    $isDoctorUnlimitedAccess: Boolean
    $isActiveInvitesBefore: Boolean
    $invitesBefore: DateTime
    $isManualSubscribed: Boolean
    $manualSubscribedTillDate: DateTime
    $planMappingId: String
    $planId: String
    $price: Float
  ) {
    updateClinic(
      clinicId: $clinicId
      name: $name
      email: $email
      phone: $phone
      address: $address
      maxDoctors: $maxDoctors
      isNoLimitDoctors: $isNoLimitDoctors
      maxPatients: $maxPatients
      isNoLimitPatients: $isNoLimitPatients
      fee: $fee
      admin: $admin
      avatar: $avatar
      nextPaymentDate: $nextPaymentDate
      isPatientUnlimitedAccess: $isPatientUnlimitedAccess
      isDoctorUnlimitedAccess: $isDoctorUnlimitedAccess
      isActiveInvitesBefore: $isActiveInvitesBefore
      invitesBefore: $invitesBefore
      isManualSubscribed: $isManualSubscribed
      manualSubscribedTillDate: $manualSubscribedTillDate
      planMappingId: $planMappingId
      planId: $planId
      price: $price
    ) {
      id
    }
  }
`;

export const UPDATE_CLINIC_MUTATION = gql`
  mutation updateClinicSelf(
    $clinicId: String!
    $name: String
    $email: String
    $phone: String
    $address: String
    $admin: AdminForClinic
    $avatar: Upload
    $enableNotification: Boolean
  ) {
    updateClinic(
      clinicId: $clinicId
      name: $name
      email: $email
      phone: $phone
      address: $address
      admin: $admin
      avatar: $avatar
      enableNotification: $enableNotification
    ) {
      id
    }
  }
`;

export const UPDATE_DOCTOR_MUTATION = gql`
  mutation updateDoctorByAdmin(
    $avatar: Upload
    $firstName: String
    $lastName: String
    $position: String
    $email: String
    $doctorID: ID!
    $maxPatients: Float
    $invitesBefore: DateTime
  ) {
    updateDoctorExternal(
      avatar: $avatar
      firstName: $firstName
      lastName: $lastName
      position: $position
      email: $email
      doctorID: $doctorID
      maxPatients: $maxPatients
      invitesBefore: $invitesBefore
    ) {
      id
      email
    }
  }
`;

export const DEACTIVATE_DOCTOR_MUTATION = gql`
  mutation deactivateDoctor($doctorID: String!) {
    deactivateDoctor(doctorID: $doctorID)
  }
`;

export const ACTIVATE_DOCTOR_MUTATION = gql`
  mutation activateDoctor($doctorID: String!) {
    activateDoctor(doctorID: $doctorID)
  }
`;
